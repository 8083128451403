.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
  }
  
  .nav-item {
    text-align: center;
    flex: 1;
    padding: 10px;
    color: #c8aa54;
    font-size: 12px;
    transition: color 0.3s;
    cursor: pointer;
  }
  
  .nav-item .icon {
    font-size: 24px;
    display: block;
    margin-bottom: 5px;
  }
  
  .nav-item.active,
  .nav-item:hover {
    color: #399562; /* لون عند التفاعل */
  }
  
  .nav-item.active .icon,
  .nav-item:hover .icon {
    color: #399562; /* لون الأيقونة عند التفاعل */
  }
  
  .nav-item span {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .nav-item span {
      font-size: 14px;
      font-weight: bold;
    }
  }
  