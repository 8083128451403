/* position: fixed;
bottom: 0;
width: 100%;
background-color: white;
display: flex;
justify-content: space-around;
padding: 10px;
box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
border-radius: 20px 20px 0 0; */
.topapp{
    margin-bottom: 100px;
}
.appbar{
    background-color: #317750;
    padding: 10px 0px 1px 0px;
    border-radius: 0px 0px 50px 50px;
    z-index: 100;

    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
  }
 
.appbar h3{
    color: white;
}